div {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.links {
    max-width: 300px;
}

