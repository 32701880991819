.live-widget {
    text-align: center;
    color: #fff;
    background-color: #212529;
    padding: 20px;
    border-radius: 10px;
}

.queue-section {
    margin-bottom: 20px;
}

.queue-section ul {
    list-style-type: none;
    padding: 0;
}

.queue-section li {
    font-size: 1.2em;
    margin: 5px 0;
}

.votes-section {
    margin-top: 20px;
}

.top-votes {
    display: flex;
    justify-content: center;
    gap: 10px; /* Reduce gap between items */
}

.top-vote-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vote-image {
    width: 80px;
    height: 80px;
    object-fit:cover;
    margin-bottom: 0px;
    border-radius: 10px;
    /* background-color: white; */
}

.vote-label {
    font-size: 1.2em;
    margin-bottom: 0px;
}

.vote-count {
    font-size: 1em;
    color: #ddd;
}

.top-vote-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* Ensure text is centered */
}

.vote-count {
    font-size: 1em;
    color: #ddd;
    margin-top: 5px; /* Add some space between the image and the count */
}
