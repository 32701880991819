.App {
  text-align: center;
}

div {
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2b7a78;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.return-home-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.return-home-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for the button */
  border-radius: 5px; /* Rounded corners */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Bold text */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.return-home-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}
